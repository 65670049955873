
import {
    DownOutlined,
    UpOutlined,
    UploadOutlined,
    CalendarOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons-vue";

import Import from "./import.vue";

import {
    createVNode
} from "vue"

export default {
    name: "AccountExpressInvoice",
    components: {
        DownOutlined,
        UpOutlined,
        UploadOutlined,
        CalendarOutlined,
        Import
    },
    data() {
        return {
            url: "/admin/express_invoice/list",
            where: {},
            selection: [],
            // 搜索表单是否展开
            searchExpand: false,
            // 快递类型
            carrier_codes: ["UPS", "Fedex"],
            // 是否显示用户导入弹窗
            showImport: false,
            // 上传路径-默认官方路径
            uploadUrl: `/admin/express_invoice/import`,
            // 接口集合
            apiList: [],
            // loading
            exportInvoicedLoading: {}
        };
    },
    computed: {
        columns() {
            return [{
                title: this.$t("API"),
                dataIndex: "api_name",
                sorter: true,
                width: 200
            },
            {
                title: this.$t("快递类型"),
                dataIndex: "carrier_code",
                sorter: true,
                width: 150
            },
            {
                title: this.$t("账号"),
                dataIndex: "account_number",
                sorter: true,
                width: 200
            },
            {
                title: this.$t("账单号码"),
                dataIndex: "invoice_number",
                sorter: true,
                width: 200
            },
            {
                title: this.$t("账单日期"),
                dataIndex: "invoice_date",
                sorter: true,
                width: 150
            },
            {
                title: this.$t("账单金额"),
                dataIndex: "amount",
                sorter: true,
                width: 150
            },
            {
                title: this.$t("采集时间"),
                dataIndex: "created",
                sorter: true,
                width: 180
            },
            {
                title: this.$t("状态"),
                sorter: true,
                // width: 100,
                slots: {
                    customRender: "status"
                }
            },
            {
                key: "action",
                title: this.$t("操作"),
                fixed: "right",
                width: 150,
                slots: {
                    customRender: "action"
                }
            }
            ];
        }
    },
    mounted() {
        this.$http.get("/admin/ship_method_api/simple_list").then(res => {
            if (res.data.code == 0) {
                this.apiList = res.data.data;
            }
        });
    },
    methods: {
        /**
         * 删除
         */
        remove(item) {

            this.$confirm({
                title: this.$t('提示'),
                content: this.$t('确定要删除选中的数据吗?'),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('请求中...', 0);

                    this.$http.post('/admin/express_invoice/delete', {
                        ids: [item.id]
                    }).then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        },
        /**
         * 导入数据
         */
        importFile(keys) {
            switch (keys.key) {
                case "offical":
                    this.uploadUrl = `/admin/express_invoice/import`;
                    this.showImport = true;
                    break;
                case "thirder":
                    this.uploadUrl = `/admin/express_invoice/upsimport`;
                    this.showImport = true;
                    break;
                default:
                    this.uploadUrl = `/admin/express_invoice/import`;
                    this.showImport = true;
                    break;
            }
        },
        /**
         * 刷新表格
         */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /**
         * 重置搜索
         */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 查看账单明细
         */
        detail(row) {
            this.$router.push({
                path: "/admin/account/express_invoice/detail",
                query: {
                    id: row.id,
                    number: row.invoice_number
                }
            });
        },
        /**
         * 开始对账
         */
        review(row) {
            this.$router.push({
                path: "/admin/account/express_invoice/master_review",
                query: {
                    id: row.id,
                    number: row.invoice_number,
                    keywords: this.where.keywords
                }
            });
        },
        /**
         * 导出对账数据
         */
        exportInvoiced(row) {
            this.exportInvoicedLoading[row.id] = true;
            this.$http
                .get(`/admin/express_invoice/invoiced_export/${row.id}`)
                .then(res => {
                    if (res.data.code === 0) {
                        const blob = this.base64ToBlob(
                            res.data.data,
                            "application/vnd.ms-excel"
                        );
                        const fileName = `Invoices-${new Date().getTime()}.xls`;

                        if ("download" in document.createElement("a")) {
                            // 非IE下载
                            const el = document.createElement("a");

                            el.download = fileName;
                            el.style.display = "none";
                            el.href = URL.createObjectURL(blob);
                            document.body.appendChild(el);

                            el.click();

                            URL.revokeObjectURL(el.href);
                            document.body.removeChild(el);
                        } else {
                            // IE10+下载
                            navigator.msSaveBlob(blob, fileName);
                        }
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(e => {
                    this.$message.error(e.message);
                }).finally(() => {
                    this.exportInvoicedLoading[row.id] = false;

                })
        },
        base64ToBlob(text, content_type) {
            var byteString = atob(text);
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var intArray = new Uint8Array(arrayBuffer);

            for (var i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }

            return new Blob([intArray], {
                type: content_type
            });
        }
    }
};
